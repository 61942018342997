import React, { useMemo } from "react";
import { IMessageData } from "../../../../interface";

interface IUSWidget {
	messageData: IMessageData;
}

const CategoryHeader: React.FC<any> = ({ header }) => {
	return (
		<div style={{ display: "flex", height: "70px", maxHeight: "70px", gap: "10px", alignItems: "center" }}>
			<div>
				<img src={header.imageUrl} alt={header.categoryName} style={{ width: "30px", height: "30px" }} />
			</div>
			<div style={{ flexGrow: 1 }}>
				<div style={{ fontWeight: "bold" }}>{header.categoryName}</div>
			</div>
		</div>
	);
};

const USWidget: React.FC<IUSWidget> = ({ messageData }) => {
	const data = useMemo(() => {
		const json = messageData.payload.replace("US_WIDGET:", "").replaceAll("&quot;", '"');
		return JSON.parse(json).body;
	}, [messageData]);

	return (
		<div style={{ maxHeight: "50%", overflowX: "auto", paddingLeft: "10px", paddingRight: "10px" }}>
			<div>
				<h4 style={{ textAlign: "center" }}>SuperApp Universal Search</h4>
			</div>
			{data.data.map((category: { categoryName: any; imageUrl: string; data: any[] }) => (
				<div key={category.categoryName} style={{ display: "flex", flexDirection: "column", justifyContent: "stretch" }}>
					<CategoryHeader header={category} />
					<div style={{ display: "flex", flexDirection: "column", justifyContent: "stretch", borderBottom: "2px solid lightgray" }}>
						{category.data.map((item, i) => {
							return (
								<div key={i} style={{ display: "flex", height: "50px", maxHeight: "50px", gap: "10px", alignItems: "center", marginBottom: "10px" }}>
									{item.imageUrl && (
										<div>
											<img src={item.imageUrl} alt={item.name} style={{ width: "50px", height: "50px" }} />
										</div>
									)}
									<div style={{ flexGrow: 1, display: "flex", gap: "10px", flexDirection: "column" }}>
										<div style={{ fontWeight: "bold" }}>{item.name}</div>
										{item.description && <div>{item.description}</div>}
									</div>
								</div>
							);
						})}
					</div>
				</div>
			))}
		</div>
	);
};

export default USWidget;
