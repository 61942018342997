import { IObject } from "../interface";

export const englishDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "Type your message here",
  CHAT_INPUT_PLACEHOLDER_DISABLED: "",
  BOT_DESCRIPTION: "Try me by asking your questions or select a topic below",
  TURN_OFF_SOUND: "Turn off sound",
  TURN_ON_SOUND: "Turn on sound",
  CHANGE_LANGUAGE: "Change language",
  EMAIL_TRANSCRIPT: "Email transcript",
  DOWNLOAD_TRANSCRIPT: "Download transcript",
  ADULT: "Adult",
  CHILD: "Children",
  INFANT: "Infant",
};

export const simplifiedChineseDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "问我任何问题",
  BOT_DESCRIPTION: "尝试向我提问或从下面选择一个话题",
  TURN_OFF_SOUND: "关闭声音",
  TURN_ON_SOUND: "打开声音",
  CHANGE_LANGUAGE: "更换语言",
  EMAIL_TRANSCRIPT: "邮件聊天记录",
  DOWNLOAD_TRANSCRIPT: "下载聊天记录",
  ADULT: "成人",
  CHILD: "孩子",
  INFANT: "婴儿",
};

export const traditionalChineseDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "問我任何問題",
  BOT_DESCRIPTION: "嘗試向我提問或從下面選擇一個話題",
  TURN_OFF_SOUND: "關閉聲音",
  TURN_ON_SOUND: "開啟聲音",
  CHANGE_LANGUAGE: "更換語言",
  EMAIL_TRANSCRIPT: "電子郵件聊天記錄",
  DOWNLOAD_TRANSCRIPT: "下載聊天記錄",
  ADULT: "成人",
  CHILD: "孩子",
  INFANT: "嬰兒",
};

export const indonesianDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "Tanya saya apa saja",
  BOT_DESCRIPTION: "Coba tanyakan pertanyaan Anda atau pilih topik di bawah",
  TURN_OFF_SOUND: "Matikan suara",
  TURN_ON_SOUND: "Nyalakan suara",
  CHANGE_LANGUAGE: "Ubah bahasa",
  EMAIL_TRANSCRIPT: "Email transkrip",
  DOWNLOAD_TRANSCRIPT: "Unduh transkrip",
  ADULT: "Dewasa",
  CHILD: "Anak",
  INFANT: "Bayi",
};

export const koreanDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "무엇이든 물어보세요",
  BOT_DESCRIPTION: "질문을 하거나 아래 주제 중 하나를 선택해보세요",
  TURN_OFF_SOUND: "소리 끄기",
  TURN_ON_SOUND: "소리 켜기",
  CHANGE_LANGUAGE: "언어 변경",
  EMAIL_TRANSCRIPT: "이메일 대화 내용",
  DOWNLOAD_TRANSCRIPT: "대화 내용 다운로드",
  ADULT: "성인",
  CHILD: "어린이",
  INFANT: "유아",
};

export const thaiDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "ถามฉันอะไรก็ได้",
  BOT_DESCRIPTION: "ลองถามคำถามของคุณหรือเลือกหัวข้อด้านล่าง",
  TURN_OFF_SOUND: "ปิดเสียง",
  TURN_ON_SOUND: "เปิดเสียง",
  CHANGE_LANGUAGE: "เปลี่ยนภาษา",
  EMAIL_TRANSCRIPT: "ส่งทรานสคริปต์ทางอีเมล",
  DOWNLOAD_TRANSCRIPT: "ดาวน์โหลดทรานสคริปต์",
  ADULT: "ผู้ใหญ่",
  CHILD: "เด็ก",
  INFANT: "เด็กทารก",
};

export const turkishDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "Bana herhangi bir şey sorun",
  BOT_DESCRIPTION:
    "Sorularınızı sorarak deneyin veya aşağıdaki konulardan birini seçin",
  TURN_OFF_SOUND: "Sesi kapat",
  TURN_ON_SOUND: "Sesi aç",
  CHANGE_LANGUAGE: "Dili değiştir",
  EMAIL_TRANSCRIPT: "E-posta ile transkript gönder",
  DOWNLOAD_TRANSCRIPT: "Transkripti indir",
  ADULT: "Yetişkin",
  CHILD: "Çocuk",
  INFANT: "Bebek",
};

export const japaneseDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "何でも聞いてください",
  BOT_DESCRIPTION:
    "質問をしてみてください、または以下のトピックから選択してください",
  TURN_OFF_SOUND: "音を消す",
  TURN_ON_SOUND: "音をつける",
  CHANGE_LANGUAGE: "言語を変更する",
  EMAIL_TRANSCRIPT: "メールでトランスクリプトを送る",
  DOWNLOAD_TRANSCRIPT: "トランスクリプトをダウンロード",
  ADULT: "アダルト",
  CHILD: "子供",
  INFANT: "幼児",
};

export const malayDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "Tanya saya apa sahaja",
  BOT_DESCRIPTION: "Cuba tanya soalan anda atau pilih topik di bawah",
  TURN_OFF_SOUND: "Matikan bunyi",
  TURN_ON_SOUND: "Hidupkan bunyi",
  CHANGE_LANGUAGE: "Tukar bahasa",
  EMAIL_TRANSCRIPT: "E-mel transkrip",
  DOWNLOAD_TRANSCRIPT: "Muat turun transkrip",
  ADULT: "Dewasa",
  CHILD: "Anak",
  INFANT: "Bayi",
};

export const vietnameseDictionary: IObject = {
  CHAT_INPUT_PLACEHOLDER: "Hãy hỏi tôi bất cứ điều gì",
  BOT_DESCRIPTION: "Hãy thử hỏi câu hỏi của bạn hoặc chọn một chủ đề dưới đây",
  TURN_OFF_SOUND: "Tắt âm thanh",
  TURN_ON_SOUND: "Bật âm thanh",
  CHANGE_LANGUAGE: "Thay đổi ngôn ngữ",
  EMAIL_TRANSCRIPT: "Gửi bản ghi cuộc trò chuyện qua email",
  DOWNLOAD_TRANSCRIPT: "Tải xuống bản ghi cuộc trò chuyện",
  ADULT: "Người lớn",
  CHILD: "Đứa trẻ",
  INFANT: "Trẻ sơ sinh",
};
