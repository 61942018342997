import { ReactElement, createContext, useContext, useEffect, useMemo, useState } from "react";
import { changeBotLanguage } from "../../services/translation.service";

interface IWidgetContextProvider extends ISettingContext {
	children: ReactElement | ReactElement[];
	sendMessage: Function;
	onLanguageChange: Function;
}

export interface ILanguageContext {
	language?: string;
	setLanguage?: (language: string) => void;
}

export interface ISetting {
	sound: boolean;
}

export interface ISettingContext {
	setting: ISetting;
	changeSetting?: Function;
}

export const defaultSetting: ISetting = { sound: true };

const SendMessageContext = createContext<Function>(() => {});
export const SettingContext = createContext<ISettingContext>({
	setting: defaultSetting,
});
const LanguageContext = createContext<ILanguageContext>({});

export const WidgetContextProvider: React.FunctionComponent<IWidgetContextProvider> = ({ children, sendMessage, setting, changeSetting, onLanguageChange }) => {
	const searchParams = new URLSearchParams(window.location.search);
	const lang = searchParams.get("lang") || "en";
	const [language, setLanguage] = useState(lang);

	useEffect(() => {
		onLanguageChange(language);
		//eslint-disable-next-line
	}, [language]);

	useEffect(() => {
		if (lang !== "en") {
			changeBotLanguage(lang);
			setLanguage(lang);
		}
	}, [lang]);

	const settingContextValue = useMemo(() => ({ setting, changeSetting }), [setting, changeSetting]);
	return (
		<LanguageContext.Provider value={{ language, setLanguage }}>
			<SendMessageContext.Provider value={sendMessage}>
				<SettingContext.Provider value={settingContextValue}>{children}</SettingContext.Provider>
			</SendMessageContext.Provider>
		</LanguageContext.Provider>
	);
};

export function useSendMessage() {
	return useContext(SendMessageContext);
}
export function useSettings() {
	return useContext(SettingContext);
}
export function useLanguage() {
	return useContext(LanguageContext);
}
