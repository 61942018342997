import { IObject } from "../interface";
import apiService from "./api.service";
import {
  englishDictionary,
  indonesianDictionary,
  japaneseDictionary,
  koreanDictionary,
  malayDictionary,
  simplifiedChineseDictionary,
  thaiDictionary,
  traditionalChineseDictionary,
  turkishDictionary,
  vietnameseDictionary,
} from "./language.dictionary";

const DICTIONARY: IObject = {
  en: englishDictionary,
  "zh-Hans": simplifiedChineseDictionary,
  "zh-Hant": traditionalChineseDictionary,
  id: indonesianDictionary,
  ja: japaneseDictionary,
  ko: koreanDictionary,
  ms: malayDictionary,
  th: thaiDictionary,
  tr: turkishDictionary,
  vi: vietnameseDictionary,
};

let activeDictionary = DICTIONARY.en;

export const getTranslation = async (language: string) => {
  return apiService("/ava-BOT/chat-widget/get_translation", {
    body: { language },
    method: "POST",
  });
};

export const changeBotLanguage = async (language: string) => {
  if (!DICTIONARY[language]) {
    try {
      const translation = await getTranslation(language);
      DICTIONARY[language] = translation;
      activeDictionary = DICTIONARY[language];
    } catch (e) {}
  } else {
    activeDictionary = DICTIONARY[language];
  }
};

export const translationProvider = (word: string) => {
  return activeDictionary[word] || DICTIONARY.en[word];
};
