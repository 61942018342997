export const BOT_NAME = "Flight Status";
export const BOT_LOGO = "https://static.airasia.com/design/icons/product-tile/app/v6/TravelBuddy.svg";

export const MESSAGE_CONTENT_TYPE = {
	QUICK_REPLIES: "quickReplies",
	QUICK_REPLY_RESPONSE: "quickReplyResponse",
	LINK: "link",
	CAROUSEL: "carousel",
	RATING: "rating",
	FLIGHT_STATUS: "flightStatus",
	PLAIN_TEXT: "plainText",
	IMAGE: "image",
	LIST_SELECTION: "listSelection",
	US_WIDGET: "usWidget",
	HOTEL_GUEST_WIDGET: "hotelGuestWidget",
	HOTEL_GUEST_WIDGET_RESPONSE: "hotelGuestWidgetResponse",
	DATE_PICKER: "datePicker",
};

export const LANGUAGES = {
	"zh-Hans": "简体中文",
	"zh-Hant": "繁體中文",
	en: "English",
	id: "Bahasa Indonesia",
	ja: "日本語",
	ko: "한국어",
	ms: "Bahasa Melayu",
	th: "ภาษาไทย",
	tr: "Türkçe",
	vi: "Tiếng Việt",
};
