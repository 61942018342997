import SockJS from "sockjs-client";
import Stomp, { Client } from "stompjs";
import { IObject, ISendMessage } from "../interface";
import apiService from "./api.service";
// @ts-ignore
import sound from "../assets/notificationSound.mp3";
import { MESSAGE_CONTENT_TYPE } from "../constants";

export let conversationId = "";
let newConversation = false;
let socketConnected = false;
let chatInitiated = false;
let stompClient: Client | null;
const notificationSound = new Audio(sound);
let reconnectInterval = 1000; // Start with 1 second
const maxReconnectInterval = 30000; // Maximum 30 seconds
let reconnectAttempts = 0;
const maxReconnectAttempts = 10;

export const playNotificationSound = () => {
  document.addEventListener("DOMContentLoaded", function () {
    notificationSound.play();
  });
};

interface IWidgetConfig {
  lang?: string;
  channel?: string;
  domain?: string;
  flowId?: string;
  customerToken?: string;
  customAttributes?: ICustomAttribute[];
  googleAccessToken?: string;
}

interface ICustomAttribute {
  key: string;
  value: string;
}

const DEFAULT_WIDGET_CONFIG: IWidgetConfig = {
  lang: "en",
  channel: "SuperApp",
  domain: "Consumer",
  flowId: process.env.REACT_APP_DEFAULT_FLOW,
  customerToken: undefined,
  customAttributes: undefined,
  googleAccessToken: undefined,
};

const getConfigFromQueryParam = async (): Promise<IWidgetConfig> => {
  const config: IWidgetConfig = {};
  const customAttributes: ICustomAttribute[] = [];

  try {
    const decryptedString = await getDecryptedString();
    const queryString = await decryptData(decryptedString);
    const searchParams = new URLSearchParams(queryString);
    Object.keys(DEFAULT_WIDGET_CONFIG).forEach((key: string) => {
      const k = key as keyof IWidgetConfig;
      const val = searchParams.get(k);
      if (val !== null) {
        if (k === "customAttributes") {
          const customParams = val.slice(1, -1);
          const customSearchParams = new URLSearchParams(customParams);
          const entries = customSearchParams.entries();
          const params = Array.from(entries);
          params.forEach((param) => {
            var key = param[0];
            var value = param[1];
            customAttributes.push({ key, value });
            config[k] = customAttributes;
          });
        } else {
          config[k] = val;
        }
      }
    });
    return config;
  } catch (error) {
    console.error(error);
    return DEFAULT_WIDGET_CONFIG;
  }
};

const getDecryptedString = (): Promise<string> => {
  return new Promise((resolve, reject) => {
    const hash = window.location.search.substring(1);
    if (hash.length === 0) {
      resolve("no hash");
    } else {
      resolve(hash);
    }
  });
};

const decryptData = (hashData: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (hashData !== "no hash") {
      apiService(`${process.env.REACT_APP_WIDGET_PATH}/deeplink/decrypt`, {
        method: "POST",
        body: { encryptedData: hashData },
      })
        .then((response) => {
          if (response.status === "success") {
            const decryptedData = response.data;
            const chatPath = decryptedData.slice(0, 22);

            if (chatPath === "/chatbot/deeplink/path") {
              const customParams = decryptedData.slice(23);
              resolve(window.location.search + "&" + customParams);
            } else {
              reject(new Error("Incorrect path"));
            }
          } else {
            reject(new Error("Incorrect path"));
          }
        })
        .catch((error) => {
          reject(new Error("Decryption failed: " + error.message));
        });
    } else {
      const urlParts = window.location.hash.split("?");
      // Check if the URL contains a query string
      if (urlParts.length < 2) {
        resolve('');
      }
      resolve(urlParts[1]);
    }
  });
};

export const login = async (val: { username: string; password: string }) => {
  const configFromQueryParam = await getConfigFromQueryParam();
  const body = {
    ...DEFAULT_WIDGET_CONFIG,
    ...configFromQueryParam,
    ...val,
  };
  return apiService("/internal-testing/login", {
    body,
    method: "POST",
    url: process.env.REACT_APP_PROXY_URL,
  }).then((resp) => {
    return resp;
  });
};

export const initChatWidget = async (val: IWidgetConfig) => {
  const configFromQueryParam = await getConfigFromQueryParam();
  const body = {
    ...DEFAULT_WIDGET_CONFIG,
    ...configFromQueryParam,
    ...val,
  };
  return apiService("/chat-widget/chat/init", { body, method: "POST" }).then(
    (resp) => {
      conversationId = resp.data.conversationId;
      newConversation = resp.data.newConversation;
      return resp;
    }
  );
};

export const sendMessage = async (body: ISendMessage) => {
  let payload: IObject = { type: "SEND_MESSAGE" };
  if (body.type === MESSAGE_CONTENT_TYPE.PLAIN_TEXT) {
    payload.payload = { type: "PLAIN_TEXT", message: body.payload };
  } else {
    payload.payload = { type: "POSTBACK", ...body.payload };
  }
  stompClient?.send(
    "/app/socket/" + conversationId,
    {},
    JSON.stringify(payload)
  );
  if (body.type !== MESSAGE_CONTENT_TYPE.PLAIN_TEXT) {
    stompClient?.send(
      "/app/socket/" + conversationId,
      {},
      JSON.stringify({
        type: "UPDATE_MESSAGE",
        payload: {
          ...body.messageData,
          payload: { ...body.messageData.payload, disabled: true },
        },
      })
    );
  }
};

export const connectWS = (onNewMessage: (p1: any) => void) => {
  if (conversationId && !stompClient) {
    const { REACT_APP_SOCKET_URL = "" } = process.env;
    
    const connect = () => {
      let sock = new SockJS(REACT_APP_SOCKET_URL);
      stompClient = Stomp.over(sock);
      stompClient.debug = () => {};
      stompClient.connect({}, function (frame) {
        socketConnected = true;
        reconnectInterval = 1000; // Reset interval on successful connection
        reconnectAttempts = 0; // Reset attempts counter
        
        stompClient?.subscribe(
          "/topic/chat-widget/" + conversationId,
          function (message) {
            onNewMessage(JSON.parse(message.body));
          }
        );
        if (newConversation && !chatInitiated) {
          chatInitiated = true;
          stompClient?.send(
            "/app/socket/" + conversationId,
            {},
            JSON.stringify({
              type: "INIT_CHAT",
            })
          );
        }
      }, function (error) {
        console.log('WebSocket connection error:', error);
        handleReconnect();
      });

      sock.onclose = function() {
        console.log('WebSocket is closed. Reconnecting...');
        handleReconnect();
      };
    };

    const handleReconnect = () => {
      if (navigator.onLine && reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          reconnectAttempts++;
          reconnectInterval = Math.min(reconnectInterval * 2, maxReconnectInterval); // Exponential backoff
          connect();
        }, reconnectInterval);
      } else {
        console.log('Max reconnect attempts reached or offline.');
      }
    };

    connect();
  }
};

export const disconnectWS = () => {
  if (socketConnected && stompClient) {
    try {
      chatInitiated = false
      stompClient?.disconnect(() => console.log("Socket Disconnected"));
      stompClient = null;
    } catch (e) {}
  }
};
