const baseUrl = process.env.REACT_APP_API_URL;

// Define the HTTP headers to be sent with the request
const headers = {
  "Content-Type": "application/json",
  // Authorization:
  //   "Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJnYXVyYXZrZXNoYXJ3YW5pQGFpcmFzaWEuY29tIiwiYXVkIjoiQXV0aERhc2hib2FyZFN2YyIsImlhdCI6MTY4MDc3MzEzOCwiZXhwIjoxNjgyMDY5MTM4fQ.wJyJ72yki-ETMop_lkXDSDRAhmXf2GrfjlWyCNUg9O4",
};

interface IApiServiceOption {
  body?: { [key: string]: any } | null;
  method: "GET" | "PUT" | "POST" | "DELETE";
  url?: string;
}

const apiService = (endpoint: string, options: IApiServiceOption) => {
  const { method = "GET", body = null, url = "" } = options;

  const requestOptions: RequestInit = {
    method,
    headers: headers,
    // mode: 'no-cors'
  };

  if (body)
    requestOptions.body = JSON.stringify(body);

  return fetch(`${url || baseUrl}${endpoint}`, requestOptions)
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => console.error(error));
};

export default apiService;
