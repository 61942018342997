// import { useMemo } from "react";
import { useLanguage } from "../components/ChatWidget/WidgetContext";
import { translationProvider } from "../services/translation.service";

const useTranslate = () => {
  useLanguage();
  const translate = (w: string) => translationProvider(w);
  return translate;
};

export default useTranslate;
