import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Route, HashRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ChatWidget from "./components/ChatWidget/ChatWidget";
import Login from "./components/Login/Login";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/*" element={<ChatWidget />}></Route>
        </Routes>
      </Router>
    </LocalizationProvider>
  );
}

export default App;
