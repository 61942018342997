import React, { FC, FormEvent, memo, useState } from "react";
import styles from "./chatInput.module.css";
import arrowIcon from "../../assets/icons/arrow_upward.svg";
import classNames from "classnames";
import { useSendMessage } from "../ChatWidget/WidgetContext";
import { MESSAGE_CONTENT_TYPE } from "../../constants";
import useTranslate from "../../hooks/useTranslate";

interface IChatInputProps {
  disabled: boolean
}

const ChatInput: FC<IChatInputProps> = ({disabled}) => {
  const [message, setMessage] = useState<string>("");
  const translate = useTranslate();
  const sendMessage = useSendMessage();

  const onSend = (e: FormEvent) => {
    e.preventDefault();

    if (!message) {
      return;
    }
    sendMessage({
      type: MESSAGE_CONTENT_TYPE.PLAIN_TEXT,
      payload: message
    });
    setMessage("");
  };

  return (
    <div className={styles.chatInputContainer}>
      <form onSubmit={onSend}>
        <div className={styles.sendMessage}>
          <input
            name="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            autoComplete="off"
            className={styles.chatInput}
            placeholder={disabled ? translate("CHAT_INPUT_PLACEHOLDER_DISABLED") : translate("CHAT_INPUT_PLACEHOLDER")}
            disabled={disabled}
          />
          <button
            type="submit"
            className={classNames(
              styles.sendButton,
              message ? styles.fadeIn : ""
            )}
          >
            <img className="avaicon" alt="send message" src={arrowIcon} />
          </button>
        </div>
      </form>
    </div>
  );
};

export default memo(ChatInput);
