import { BOT_LOGO, BOT_NAME } from "../../constants";
import useTranslate from "../../hooks/useTranslate";
import styles from "./botProfile.module.css";

const BotProfile = () => {
	const translate = useTranslate();
	return (
		<>
			{false && (
				<div className={styles.botProfile}>
					<img className={styles.botIcon} src={BOT_LOGO} alt="Bot DP" />
					<h6 className={styles.botName}>{BOT_NAME}</h6>
					<p className={styles.botDescription}>{translate("BOT_DESCRIPTION")}</p>
				</div>
			)}
		</>
	);
};

export default BotProfile;
