import React from "react";
import styles from "./Login.module.css";
import { login } from "../../services/chat.service";


const LoginForm: React.FC = () => {
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const data = {
      username,
      password,
    };

		login(data).then((resp) => {
			if (resp.status === "OK" && resp.message) {
        window.open(resp.message);
			}
		});


  };

  return (
    <div className={styles.container}>
      <h1 className={styles.logo}>AirAsia</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor="username" className={styles.label}>
          Username
        </label>
        <input
          type="text"
          id="username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          required
          className={styles.input}
        />
        <label htmlFor="password" className={styles.label}>
          Password
        </label>
        <input
          type="password"
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className={styles.input}
        />
        <button type="submit" className={styles.button}>
          Login
        </button>
      </form>
    </div>
  );
};

const Login: React.FC = () => {
  return (
    <div className={styles.app}>
      <LoginForm />
    </div>
  );
};

export default Login;
