import React, { Suspense, lazy, useEffect, useRef } from "react";
import styles from "./chatWindow.module.css";
import BotProfile from "../BotProfile/BotProfile";
import { IMessageData } from "../../interface";
import { BOT_LOGO } from "../../constants";
import classNames from "classnames";
import TypingGif from '../../assets/icons/loading_dots.gif'

const MessageContent = lazy(() => import("../MessageContent/MessageContent"));

interface IChatWindowProps {
  messages: Array<IMessageData>;
  typing: boolean;
}

const ChatWindow = (props: IChatWindowProps) => {
  const { messages, typing } = props;
  const messageContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (messageContainerRef?.current) {
        messageContainerRef?.current.scrollTo({
          top: messageContainerRef?.current.scrollHeight,
          behavior: "smooth",
        });
      }
    }, 100);
  }, [messages, typing]);

  return (
    <div className={styles.chatWindowContainer} ref={messageContainerRef}>
      <BotProfile />
      {messages.map((message, index) => (
        <div key={message.bubbleId} className={classNames(styles.message)}>
          {message.role === "BOT" && (
            <span className={styles.chatterIcon}>
              {(index === messages.length - 1 ||
                messages[index + 1].role !== "BOT") && (
                <img src={BOT_LOGO} alt="Bot Logo" />
              )}
            </span>
          )}
          <Suspense fallback="">
            <MessageContent messageData={message} />
          </Suspense>
        </div>
      ))}

      {typing && (
        <div className={`${styles.message} ${styles.received}`}>
          <span className={styles.chatterIcon}>
            <img src={BOT_LOGO} alt="" />
          </span>
          <img src={TypingGif} height="35px" alt="Typing Loader" />
        </div>
      )}
    </div>
  );
};

export default ChatWindow;
